import http from '../api/httpRequest.js'
export default {
    state:{
        loadOnce:false,
        enableData:[]
    },
    mutations:{
        updateEnableData(state,data){
            state.enableData=data;
        }
    },
    actions:{
        loadQuickReplys(context){
            return new Promise((rej,err)=>{
                http({
                    url: '/qreply/getEnableListSort',
                    method: 'post',
                }).then((data) => {
                    context.commit("updateEnableData",data)
                })
            });
        }
    }
}