import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../view/Login'
import Register from '../view/Register'
import Home from '../view/Home'
// 安装路由
Vue.use(VueRouter);

// 配置导出路由
export default new VueRouter({
    routes: [{
        path: "/",
        redirect: "/login"
    },
        {
            name: "Login",
            path: '/login',
            component: Login
        },
        {
            name: "Register",
            path: '/register',
            component: Register
        },
        {
            name: "CardAdmin",
            path: '/cardAdmin',
            component: () => import("../view/CardAdmin")
        },
        {
            name: "AdminMainWithAwei",
            path: "adminMainWithAwei",
            component: () => import("../view/AdminMain"),
            children: [
                {
                    name: "CardManager",
                    path: '/cardManager',
                    component: () => import("../view/CardManager")
                },
                {
                    name: "HostManager",
                    path: '/hostManager',
                    component: () => import("../view/HostManager")
                },
            ]
        },

        {
            name: 'Talk',
            path: '/talk/:id',
            component: () => import("../view/Talk")
        },
        {
            name: "Home",
            path: '/home',
            component: Home,
            children: [
                {
                    name: "Chat",
                    path: "/home/chat",
                    component: () => import("../view/Chat"),
                },
                {
                    name: "Qrcode",
                    path: "/home/Qrcode",
                    component: () => import("../view/Qrcode"),
                },
                {
                    name: "Welcome",
                    path: "/home/welcome",
                    component: () => import("../view/Welcome"),
                },
                {
                    name: "Reply",
                    path: "/home/reply",
                    component: () => import("../view/Reply"),
                },
                {
                    name: "UserList",
                    path: "/home/userlist",
                    component: () => import("../view/UserList"),
                },
                {
                    name: "Statics",
                    path: "/home/statics",
                    component: () => import("../view/Statics"),
                },
                {
                    name: "GroupText",
                    path: "/home/grouptext",
                    component: () => import("../view/GroupText"),
                },
                {
                    name: "Friend",
                    path: "/home/friend",
                    component: () => import("../view/Friend"),
                },
                {
                    name: "GROUP",
                    path: "/home/group",
                    component: () => import("../view/Group"),
                }
            ]
        }
    ]
});
